.app-wrapp {
  width: 330px;
  height: 558px;
  margin: 15vh auto 10px;
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZZJREFUeNq8VtFxgzAMBU/ACOkGZIKYDZK/XL+aCRImwZ6AfvXyl24QMgHeoBnBI1TinnuqC2kDKbrTcRek9yTZeiRNbth2+5zRQ5OvyPPotSO/kDfH45sfwkhvAB/I9+QZfm6iMI0ng1ty00eU9oBzpSfyBUAtJb4PFLJGEUx2Jd9QrBskoIQXetSoaheAQapFNx6jcYKoxnvOe/1BAJAWs+VKrvQbg1Y985fnUFJsQ7ELdM6xy0Ceipm3qGAJ8Arn8Bfj+ZcgadEh43iFgANmvhsB3uVzDucyBrC6/BTVf3C7FFBgLOdknBUY1xmjelLi8CyCqmS8hVwLTK2wRAnfGBx0PoEgZwxxrVcKgE20PFNMi8XMVfQyewDBNwyV/LPFBP4BmD4mcNHcppo8T6cguZ2eYL3dBHDeJQdtYrsoMHqoIls5gSDk7oMgKmg4L4ZGF0xoRoAbbPEa47FSiwz0vGbBYuG6k0SKXQ0s83WL0MUGd/gkSIpfzsRBf0oh1xnk3s/7wZnlkznLR//evy1D3QX7FGAAhvHiHphAQV8AAAAASUVORK5CYII="),
    auto;
  padding: 10px 0 0 10px;
  position: relative;
  left: -10px;
  top: -10px;
}
.app-wrapp .app-container {
  width: 320px;
  height: 548px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  overflow: hidden;
}
/* Main view */
.view-main {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0px;
  right: 0;
  -webkit-transition: right 0.3s linear;
  -moz-transition: right 0.3s linear;
  -o-transition: right 0.3s linear;
  transition: right 0.3s linear;
}
.view-main.deactive {
  right: 30%;
}
.view-main .header-wrapp {
  width: 100%;
  height: 36%;
  position: relative;
  background: #7ed08cc7;
}
.view-main .header-wrapp .app-detail {
  text-align: center;
  padding: 20px 5px 5px;
  color: #ffffff;
  text-shadow: 0 0 15px #5a544a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.view-main .header-wrapp .app-detail img {
  width: 75px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 15px #ffa000;
  -moz-box-shadow: 0 0 15px #ffa000;
  box-shadow: 0 0 15px #ffa000;
  border: 3px solid #ffffff;
}
.view-main .header-wrapp .app-detail .status {
  font-size: 13px;
}
.view-main .header-wrapp .wave-wrapp {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;
}
.view-main .header-wrapp .wave-wrapp .wave {
  display: block;
  width: 100%;
  margin: 0;
  height: 50px;
}
.view-main .header-wrapp .wave-wrapp .wave .parallax > use {
  -webkit-animation: move-forever 12s linear infinite;
  -moz-animation: move-forever 12s linear infinite;
  -o-animation: move-forever 12s linear infinite;
  animation: move-forever 12s linear infinite;
}
.view-main .header-wrapp .wave-wrapp .wave .parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
  -moz-animation-delay: -2s;
  -o-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-transform: translate(-110px, 0%);
  -moz-transform: translate(-110px, 0%);
  -o-transform: translate(-110px, 0%);
  transform: translate(-110px, 0%);
}
.view-main .header-wrapp .wave-wrapp .wave .parallax > use:nth-child(2) {
  -webkit-animation-delay: -2s;
  -moz-animation-delay: -2s;
  -o-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-animation-duration: 7s;
  -moz-animation-duration: 7s;
  -o-animation-duration: 7s;
  animation-duration: 7s;
}
.view-main .header-wrapp .wave-wrapp .wave .parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
  -moz-animation-delay: -4s;
  -o-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -o-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-transform: translate(-65px, 0%);
  -moz-transform: translate(-65px, 0%);
  -o-transform: translate(-65px, 0%);
  transform: translate(-65px, 0%);
}
.view-main .content-wrapp {
  width: 100%;
  height: 64%;
  position: relative;
}
.view-main .content-wrapp .nav-group {
  position: absolute;
  width: 100%;
  top: 0;
  box-shadow: 0px 3px 5px #cccccc;
}
.view-main .content-wrapp #master-nav {
  text-align: center;
  padding-top: 15px;
  border-bottom: 1px solid #f5f5f5;
}
.view-main .content-wrapp #master-nav ul {
  list-style: none;
  padding: 0px;
  position: relative;
}
.view-main .content-wrapp #master-nav ul li {
  display: inline-block;
  width: 15%;
  font-size: 20px;
  padding: 5px 0;
  margin: 0 13px;
}
.view-main .content-wrapp #master-nav ul li i {
  color: #757575;
  font-size: 20px;
}
.view-main .content-wrapp #master-nav ul li.active i {
  color: #ffc107;
}
.view-main .content-wrapp #master-nav ul #active-line {
  position: absolute;
  bottom: -1px;
  height: 2px;
  background: #ffc107;
  padding: 0px;
  border-radius: 45px;
  left: 0px;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -o-transition: width 0.5s;
  transition: width 0.5s;
}
.view-main .content-wrapp #master-nav-items {
  position: absolute;
  width: 100%;
  top: 53px;
  bottom: 0;
}
.view-main .content-wrapp #master-nav-items > div {
  width: 100%;
  height: auto;
  position: relative;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: left 0.5s, opacity 0.4s;
  -moz-transition: left 0.5s, opacity 0.4s;
  -o-transition: left 0.5s, opacity 0.4s;
  transition: left 0.5s, opacity 0.4s;
}
.view-main .content-wrapp #master-nav-items > div.active {
  left: 0;
  opacity: 1;
}
.view-main .content-wrapp #master-nav-items > div.after {
  left: 100%;
}
.view-main .content-wrapp #master-nav-items > div.before {
  left: -100%;
}
.view-main .content-wrapp .messages-list {
  padding-top: 10px;
}
.view-main .content-wrapp .messages-list ul {
  list-style: none;
  padding: 0;
}
.view-main .content-wrapp .messages-list ul > li {
  width: 100%;
  height: 75px;
  overflow: hidden;
  border-bottom: 1px solid #f2f2f2;
}
.view-main .content-wrapp .messages-list ul > li .sender-image {
  width: 75px;
  height: 75px;
  float: left;
  text-align: center;
  padding-top: 8px;
}
.view-main .content-wrapp .messages-list ul > li .sender-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.view-main .content-wrapp .messages-list ul > li .mes-detail {
  margin-left: 75px;
  padding: 5px 15px 5px 5px;
}
.view-main .content-wrapp .messages-list ul > li .mes-detail .mes-col1 {
  width: 65%;
  float: left;
  padding-right: 5px;
}
.view-main
  .content-wrapp
  .messages-list
  ul
  > li
  .mes-detail
  .mes-col1
  .sender-name {
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 3px;
}
.view-main
  .content-wrapp
  .messages-list
  ul
  > li
  .mes-detail
  .mes-col1
  .mes-preview {
  font-size: 12px;
  color: #757575;
}
.view-main .content-wrapp .messages-list ul > li .mes-detail .mes-col2 {
  font-size: 12px;
}
.view-main
  .content-wrapp
  .messages-list
  ul
  > li
  .mes-detail
  .mes-col2
  .send-time {
  float: right;
}
.view-main
  .content-wrapp
  .messages-list
  ul
  > li
  .mes-detail
  .mes-col2
  .mes-seen-status {
  float: right;
  color: #4caf50;
  position: relative;
  top: -2px;
  right: 5px;
}
.view-main
  .content-wrapp
  .messages-list
  ul
  > li
  .mes-detail
  .mes-col2
  .mes-seen-status
  i {
  font-size: 16px;
}
.view-main
  .content-wrapp
  .messages-list
  ul
  > li
  .mes-detail
  .mes-col2
  .mes-count {
  background: #ffc107;
  padding: 1px 6px;
  border-radius: 50px;
  color: #ffffff;
  float: right;
  margin: 10px 0 0;
  clear: right;
}
.view-main .content-wrapp .alphabet {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  z-index: 1;
}
.view-main .content-wrapp .alphabet > div:after {
  content: " ";
  background: #ffa000;
  width: 3px;
  height: 3px;
  position: relative;
  display: flex;
  margin: auto;
  border-radius: 50%;
}
.view-main .content-wrapp .alphabet > div:last-child:after {
  content: "";
  display: none;
}
.view-main .content-wrapp .contact-list {
  font-size: 14px;
}
.view-main .content-wrapp .contact-list ul {
  list-style: none;
}
.view-main .content-wrapp .contact-list > ul {
  padding-top: 5px;
}
.view-main .content-wrapp .contact-list > ul > li .title {
  text-indent: 10px;
  background: #f9f9f9;
  color: #757575;
  width: 100%;
  float: left;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 9px 7px -8px #d9d9d9;
}
.view-main .content-wrapp .contact-list > ul > li ul {
  padding-top: 20px;
}
.view-main .content-wrapp .contact-list > ul > li ul li {
  padding: 10px;
  display: block;
  border-bottom: 1px solid #f5f5f5;
  text-transform: capitalize;
  font-size: 13px;
}
.view-main .content-wrapp .profile-setting .information {
  padding-top: 5px;
}
.view-main .content-wrapp .profile-setting .information .private > p,
.view-main .content-wrapp .profile-setting .information .public > p {
  font-size: 12px;
  font-weight: bold;
  padding: 5px 15px;
  background: #f2f2f2;
}
.view-main .content-wrapp .activity {
  text-align: center;
  padding: 40px 5px 5px;
  line-height: 40px;
}
.view-main .content-wrapp .activity i {
  font-size: 60px;
  color: #ffc107;
}
.view-message {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background: #f2f2f2;
  z-index: 1;
  -webkit-box-shadow: 0 0 15px #999999;
  -moz-box-shadow: 0 0 15px #999999;
  box-shadow: 0 0 15px #999999;
  right: -110%;
  -webkit-transition: right 0.5s;
  -moz-transition: right 0.5s;
  -o-transition: right 0.5s;
  transition: right 0.5s;
}
.view-message.active {
  right: 0;
}
.view-message .status-bar {
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: 4;
  -webkit-box-shadow: 0 0 15px #cccccc;
  -moz-box-shadow: 0 0 15px #cccccc;
  box-shadow: 0 0 15px #cccccc;
}
.view-message .status-bar .back-arrow {
  float: left;
  color: #ffa000;
  padding: 7px;
}
.view-message .status-bar .back-arrow i {
  font-size: 35px;
}
.view-message .status-bar .sender-name {
  font-weight: bold;
  text-align: center;
  padding: 15px 50px 0 0;
}
.view-message .messages-area {
  position: absolute;
  width: 100%;
  top: 50px;
  bottom: 63px;
  z-index: 2;
}
.view-message .messages-area ul {
  list-style: none;
  padding-top: 5px;
}
.view-message .messages-area ul li {
  display: block;
  height: auto;
  clear: both;
  overflow: auto;
  position: relative;
  width: 100%;
  opacity: 1;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.view-message .messages-area ul li > div {
  position: relative;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  font-size: 13px;
  margin: 3px 20px 10px;
  max-width: 70%;
  word-wrap: break-word;
}
.view-message .messages-area ul li > div span {
  display: block;
  font-size: 10px;
  margin-top: 5px;
  text-align: right;
}
.view-message .messages-area ul li > div:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
}
.view-message .messages-area ul li.sent > div {
  background: #ffa000;
  color: #ffffff;
  float: right;
  -webkit-box-shadow: 0 0 10px #ffa000;
  -moz-box-shadow: 0 0 10px #ffa000;
  box-shadow: 0 0 10px #ffa000;
}
.view-message .messages-area ul li.sent > div:after {
  right: -6px;
  border-top-right-radius: 50px;
  border-right: 10px solid #ffa000;
  transform: rotate(160deg);
  border-top: 1px solid #ffa000;
}
.view-message .messages-area ul li.recive > div {
  background: #ffffff;
  color: #757575;
  float: left;
  box-shadow: 0 0 10px #e6e6e6;
}
.view-message .messages-area ul li.recive > div:after {
  left: -6px;
  border-top-left-radius: 50px;
  border-left: 10px solid #ffffff;
  transform: rotate(185deg);
  border-top: 1px solid #ffffff;
}
.view-message .messages-area ul li.goto {
  width: 0;
  opacity: 0;
  bottom: -500px;
  left: -50%;
}
.view-message .text-media-area {
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 3;
  overflow: hidden;
  height: auto;
  -webkit-box-shadow: 0 -1px 5px #d9d9d9;
  -moz-box-shadow: 0 -1px 5px #d9d9d9;
  box-shadow: 0 -1px 5px #d9d9d9;
}
.view-message .text-media-area .message-form {
  height: 62px;
}
.view-message .text-media-area .message-form > button {
  display: inline-block;
  width: 60px;
  text-align: center;
  font-size: 15px;
  color: #ffa000;
  float: right;
  background: transparent;
  border: none;
  padding: 20px 0 15px;
  position: relative;
  z-index: 3;
}
.view-message .text-media-area .message-form input {
  font-family: "Open Sans", sans-serif;
  padding: 5px;
  border: 0px;
  position: absolute;
  padding: 22px 60px 21px 15px;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #757575;
  background: transparent;
  z-index: 2;
}
.form-group {
  padding: 5px;
  border-bottom: 1px solid #f2f2f2;
}
.form-group > span {
  display: inline-block;
  width: 40px;
  text-align: center;
  font-size: 21px;
  color: #212121;
}
.form-group > span i {
  position: relative;
  top: 3px;
  font-size: 22px;
}
.form-group input[type="text"],
.form-group textarea {
  font-family: "Open Sans", sans-serif;
  padding: 5px;
  border: 0px;
  margin-left: 5px;
  position: absolute;
  right: 0;
  left: 40px;
  width: 80%;
  font-size: 12px;
  color: #757575;
  background: transparent;
}
.form-group textarea {
  resize: none;
}
.form-group.h-80 {
  height: 80px;
}
.reset {
  -webkit-transition: transform 0.4s;
  -moz-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}
[data-viewport="true"] {
  overflow: hidden;
}
[data-viewport="true"] > span.viewportShadow {
  content: " ";
  position: absolute;
  width: 100%;
  height: 5px;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
/* Keyframes */
@-webkit-keyframes move-forever {
  0% {
    -webkit-transform: translate(-90px, 0%);
    -moz-transform: translate(-90px, 0%);
    -o-transform: translate(-90px, 0%);
    transform: translate(-90px, 0%);
  }
  100% {
    -webkit-transform: translate(85px, 0%);
    -moz-transform: translate(85px, 0%);
    -o-transform: translate(85px, 0%);
    transform: translate(85px, 0%);
  }
}
@-moz-keyframes move-forever {
  0% {
    -webkit-transform: translate(-90px, 0%);
    -moz-transform: translate(-90px, 0%);
    -o-transform: translate(-90px, 0%);
    transform: translate(-90px, 0%);
  }
  100% {
    -webkit-transform: translate(85px, 0%);
    -moz-transform: translate(85px, 0%);
    -o-transform: translate(85px, 0%);
    transform: translate(85px, 0%);
  }
}
@-ms-keyframes move-forever {
  0% {
    -webkit-transform: translate(-90px, 0%);
    -moz-transform: translate(-90px, 0%);
    -o-transform: translate(-90px, 0%);
    transform: translate(-90px, 0%);
  }
  100% {
    -webkit-transform: translate(85px, 0%);
    -moz-transform: translate(85px, 0%);
    -o-transform: translate(85px, 0%);
    transform: translate(85px, 0%);
  }
}
@-o-keyframes move-forever {
  0% {
    -webkit-transform: translate(-90px, 0%);
    -moz-transform: translate(-90px, 0%);
    -o-transform: translate(-90px, 0%);
    transform: translate(-90px, 0%);
  }
  100% {
    -webkit-transform: translate(85px, 0%);
    -moz-transform: translate(85px, 0%);
    -o-transform: translate(85px, 0%);
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever {
  0% {
    -webkit-transform: translate(-90px, 0%);
    -moz-transform: translate(-90px, 0%);
    -o-transform: translate(-90px, 0%);
    transform: translate(-90px, 0%);
  }
  100% {
    -webkit-transform: translate(85px, 0%);
    -moz-transform: translate(85px, 0%);
    -o-transform: translate(85px, 0%);
    transform: translate(85px, 0%);
  }
}

.creditCard .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1em;
  width: 45px;

  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  padding-top: 180px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 165px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 165px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #2364d2;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    height: 180px;
  }
}
.card-item.-active .card-item__side.-front {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #08142f;
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
.card-item__chip {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}
.card-item__typeImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__number {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
}
.card-item__dateItem span {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__dateTitle {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}

.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Source Sans Pro", sans-serif;
}
.card-input__input:hover,
.card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
@keyframes example {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(-90);
  }
}
