:root {
  --container-padding: 35px;
  --primary: black;
  --secondary: white;
  --second: #4a664e;
}

* {
  box-sizing: border-box;
}

html,
body {
  background-color: #f8f8f8;
  overscroll-behavior-y: contain;
  /*overflow: hidden;*/
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  border: 0;
  border-radius: 0;
  background: transparent;
  color: inherit;
  font-size: 1em;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}



.app-layout {
  background-color: #fff;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin-top: auto;
  position: absolute;
  overflow: hidden;
  margin: auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-top: calc(var(--container-padding) / 1.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.app-layout__system-bar {
  position: relative;
  z-index: 100500;
}
.app-layout__system-bar img {
  width: 100%;
}

.container {
  padding: 0 var(--container-padding);
}

.slider {
  height: 100%;
}

.slider__container {
  position: relative;
  height: 100%;
}

.slider__item {
  display: flex;
  justify-content: center;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  will-change: transform, opacity;
}

.slider__inner {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  will-change: box-shadow;
  border-radius: 20px;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slider__inner > video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.screen-modal__scroll {
  height: 100%;
  will-change: transform, opacity;
}
.screen-modal__scroll > div {
  height: 100%;
  overflow: auto;
}

.screen-modal-root {
  --container-padding2: calc(var(--container-padding) / 1.5);
  --container-padding3: calc(var(--container-padding) / 2);
  --margin: calc(var(--container-padding) / 4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.screen-modal-root > .container {
  will-change: transform, opacity;
}
.screen-modal-root .container {
  padding: 0 var(--container-padding2);
}

.screen-modal {
  background-color: white;
  position: absolute;
  height: 50%;
  width: 100%;
  left: 0;
  bottom: 0;
  will-change: transform;
}
.screen-modal .container {
  padding: var(--container-padding3) var(--container-padding2);
}
.screen-modal .screen-modal__content {
  padding-top: var(--container-padding);
  border-bottom: 1px solid #d8d8d8;
}
.screen-modal .screen-modal__content .header__title {
  margin-bottom: var(--margin);
  padding-right: 0;
}
.screen-modal .screen-modal__content .header__address {
  margin-bottom: var(--margin);
}
.screen-modal p {
  margin-top: 0;
}

.header {
  position: relative;
  height: 8vh;
  overflow: hidden;
  margin: 1em 0;
  will-change: transform, opacity;
}
.header > div {
  position: absolute;
  will-change: transform, opacity;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.header__item {
  width: 100%;
}

.header__title {
  font-size: 1.5em;
  font-weight: 700;
  color: var(--primary);
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  -webkit-box-flex: 1;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.header__top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.header__address {
  white-space: pre-wrap;
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  font-style: normal;
}
.header__address .fas {
  margin-right: 10px;
}

.header__date {
  font-size: 15px;
  font-weight: 600;
}

.footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.button {
  padding: calc(var(--container-padding) / 3);
}

.events-screen {
  height: 100%;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.events-screen__slider {
  position: relative;
  margin-bottom: 25px;
  width: 78%;
  max-height: 50%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex: 1;
}
.events-screen__slider .slider {
  padding-left: var(--container-padding);
}

.burger-button {
  font-size: 25px;
}

.event-button {
  background-color: var(--second);
  color: var(--secondary);
  border-radius: 1.5em;
  border: 1px solid #eee;
  box-shadow: 0px 1px 5px 0px rgba(232, 232, 232, 1);
  letter-spacing: 0.01em;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 1;
}
.event-button .fas {
  font-size: 12px;
  margin-right: 5px;
}

.nav-bar__back {
  color: var(--primary);
  padding-left: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.nav-bar__back .fas {
  margin-right: 20px;
}

.credits {
  font-size: 0.8em;
  margin-bottom: 1em;
  text-align: center;
}
.credits a {
  font-weight: 600;
  color: #7ed08cc7;
  text-decoration: none;
}
.credits a:hover {
  color: #7ed08cc7;
  text-decoration: underline;
}

.love {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/42764/heart-smil.svg);
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  width: 16px;
}

.wave-wrapp .wave {
  display: block;
  width: 100%;
  margin: 0;
  height: 50px;
}
.wave-wrapp .wave .parallax > use {
  -webkit-animation: move-forever 12s linear infinite;
  -moz-animation: move-forever 12s linear infinite;
  -o-animation: move-forever 12s linear infinite;
  animation: move-forever 12s linear infinite;
}
.wave-wrapp .wave .parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
  -moz-animation-delay: -2s;
  -o-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-transform: translate(-110px, 0%);
  -moz-transform: translate(-110px, 0%);
  -o-transform: translate(-110px, 0%);
  transform: translate(-110px, 0%);
}
.wave-wrapp .wave .parallax > use:nth-child(2) {
  -webkit-animation-delay: -2s;
  -moz-animation-delay: -2s;
  -o-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-animation-duration: 7s;
  -moz-animation-duration: 7s;
  -o-animation-duration: 7s;
  animation-duration: 7s;
}

.wave-wrapp .wave .parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
  -moz-animation-delay: -4s;
  -o-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -o-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-transform: translate(-65px, 0%);
  -moz-transform: translate(-65px, 0%);
  -o-transform: translate(-65px, 0%);
  transform: translate(-65px, 0%);
}
.header-wrapp {
  width: 100%;
  height: 36%;
  position: relative;
  background: #ffc107;
}

.rotate {
  animation: rotation 3s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
.ql-align-right {
  text-align: right !important;
}
.ql-align-left {
  text-align: left !important;
}
.ql-align-justify {
  text-align: justify !important;
}
.ql-align-center {
  text-align: center !important;
}


.nxt {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #212529; 
  width:100%;
}

.nxt.input-round {
  font-size: 15px;
  
  border-radius: 30px;
  background: #fff;
  border: 1px solid #ccc;
  outline:none;
  padding: 12px 12px;
  margin-bottom: 20px

}

.nxt.input-round:focus{
  border:1px solid #141A30;
  box-shadow: 0px 0px 3px 1px #141A30;
}