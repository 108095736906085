/* These styles are used if a demo specific stylesheet is not present */

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

:root {
  --color-blue: #9abc66;
  --color-white: #fff;
  --curve: cubic-bezier(0.42, 0, 0.275, 1.155);
  --color-grey: #000000bf;
  --color-grey2: #00000069;
}

@font-face {
  font-family: "CustomFont";
  src: url("./Sanchez-Italic.ttf");
  src: url("./Sanchez-Regular.ttf");
  }


.noBorder div{
  border-bottom: none !important;


}

.noBorder div::before{
  border-bottom: none !important;
}


.noBorder div input{
  border-bottom: none !important;
}

.noBorder div input::before{
  border-bottom: none !important;
}

.star {
  position: absolute;
  animation: grow 3s infinite;
  fill: var(--color-blue);
  opacity: 0;
}
.star:nth-child(1) {
  width: 12px;
  height: 12px;
  left: 12px;
  top: 16px;
}
.star:nth-child(2) {
  width: 18px;
  height: 18px;
  left: 168px;
  top: 84px;
}
.star:nth-child(3) {
  width: 10px;
  height: 10px;
  left: 32px;
  top: 162px;
}
.star:nth-child(4) {
  width: 20px;
  height: 20px;
  left: 82px;
  top: -12px;
}
.star:nth-child(5) {
  width: 14px;
  height: 14px;
  left: 125px;
  top: 162px;
}
.star:nth-child(6) {
  width: 10px;
  height: 10px;
  left: 16px;
  top: 16px;
}
.star:nth-child(1) {
  animation-delay: 1.5s;
}
.star:nth-child(2) {
  animation-delay: 3s;
}
.star:nth-child(3) {
  animation-delay: 4.5s;
}
.star:nth-child(4) {
  animation-delay: 6s;
}
.star:nth-child(5) {
  animation-delay: 7.5s;
}
.star:nth-child(6) {
  animation-delay: 9s;
}

.checkmark {
  position: relative;
  padding: 30px;
  animation: checkmark 5m var(--curve) both;
}
.checkmark__check {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, -50%, 0);
  fill: var(--color-white);
}
.checkmark__background {
  fill: var(--color-blue);
  animation: rotate 35s linear both infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes grow {
  0%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes checkmark {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  10%,
  50%,
  90% {
    opacity: 1;
    transform: scale(1);
  }
}

.chat.chat-history.message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 15px;
  /*width: 95%;*/
  position: relative;
}
.chat.chat-history.message:after {
  bottom: 100%;
  left: 13%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--color-grey);
  border-width: 10px;
  margin-left: -10px;
}

.chat.chat-history.my-message {
  background: var(--color-grey);
  margin-left: 30px;
}
.chat.chat-history.other-message {
  background: var(--color-grey2);
  margin-right: 35px;
}
.chat.chat-history.other-message:after {
  border-bottom-color: var(--color-grey2);
  left: 88%;
}




